/*
 * Get Book and WWW URLs - defines global urls vars to be used throughout princess.com
 */
var pageProtocol = window.location.protocol,
    host_name = window.location.hostname,
    host_name_split = host_name.split('.'),
    sub_domain = host_name_split[0],
    domain,
    domain_extn,
    shared_path,
    sub_domain_substring,
    match_sub_domain_string = /author-p107522-e1006288|publish-p107522-e1006288|author-p107522-e1006306|publish-p107522-e1006306|author-p107522-e1006315|publish-p107522-e1006315|dev1|dev|stage|qa|stg|uat|tst|preprod|esla-princess-uat|esla-princess|es-uat|es|jajp-princess-uat|jajp-princess|jp-uat|jp|enau-princess-uat|enau-princess|au-uat|au|engb-princess-uat|engb-princess|gb-uat|gb|int-qaapi4/i;

if (sub_domain === 'localhost') {
    sub_domain_substring = ['stage'];
} else {
    domain = host_name_split[1];
    domain_extn = host_name_split[2];
    sub_domain_substring = sub_domain.match(match_sub_domain_string);
}

// sub_domain_substring need to set array for prod to override null that is returned from sub_domain.match
if (sub_domain_substring === null) {
    sub_domain_substring = ['prod'];
}

// set shared_path based on if www in present. Previously it was evaluating pageProtocol, but that will not work since everything is secured
if (sub_domain.indexOf('www') > 0) {
    shared_path = "share";
} else {
    shared_path = "global";
}

// all possible environments
var hostDomain = {
    'dev': {
        'book':  'devbook',
        'ezbook':'devezbook',
        'p13n':  'devp13n',
        'www':   'devwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'dev',
        'name':  '.princess.com',
        'pclClientId': '37436d81abdad802b2c8bb69fad36ffb',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENda8aaae4efb64d7e9edb06e5e20c502d-development.js',
        'pclOnetrustLibrary': '//cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d-test',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'dev1': {
        'book':  'dev1book',
        'ezbook':'dev1ezbook',
        'p13n':  'dev1p13n',
        'www':   'dev1www',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'dev',
        'name':  '.princess.com',
        'pclClientId': '37436d81abdad802b2c8bb69fad36ffb',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENda8aaae4efb64d7e9edb06e5e20c502d-development.js',
        'pclOnetrustLibrary': '//cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d-test',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'stage': {
        'book':  'stagebook',
        'ezbook':'stageezbook',
        'p13n':  'stagep13n',
        'www':   'stagewww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'stage',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-6fb49ce22124-development.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'uat': {
        'book':  'uatbook',
        'ezbook':'uatezbook',
        'p13n':  'p13n',
        'www':   'uatwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'uat',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'preprod': {
        'book':  'preprodbook',
        'ezbook':'preprodezbook',
        'p13n':  'p13n',
        'www':   'preprodwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'preprod',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-EN25c15bcff20640ce824259ab3b6c09ba-staging.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-EN247337a09a134160befee6433f3bfdc0-staging.min.js',
    },
    'prod': {
        'book':  'book',
        'ezbook':'ezbook',
        'p13n':  'p13n',
        'www':   'www',
        'api':   'api1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.princess.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },

    // TRANSLATED ENVIRONMENTS
    // Spanish
    'esla-princess-uat': {
        'book':  'esla-princess-uatbook',
        'ezbook':'uatezbook',
        'p13n':  'esla-princess-uatp13n',
        'www':   'esla-princess-uat',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'uat',
        'name':  '.onelink-translations.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'esla-princess': {
        'book':  'esla-princess-book',
        'ezbook':'ezbook',
        'p13n':  'esla-princess-p13n',
        'www':   'esla-princess',
        'api':   'api1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.onelink-translations.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    'es-uat': {
        'book':  'es-uatbook',
        'ezbook':'uatezbook',
        'p13n':  'es-uatp13n',
        'www':   'es-uatwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'uat',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'es': {
        'book':  'es-book',
        'ezbook':'ezbook',
        'p13n':  'es-p13n',
        'www':   'es-www',
        'api':   'api1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.princess.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    // Japanese
    'jajp-princess-uat': {
        'book':  'jajp-princess-uatbook',
        'ezbook':'uatezbook',
        'p13n':  'jajp-princess-uatp13n',
        'www':   'jajp-princess-uat',
        'api':   'qaapi1',
        'gwapi':  'gw.qa-api',
        'envir': 'uat',
        'name':  '.onelink-translations.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'jajp-princess': {
        'book':  'jajp-princess-book',
        'ezbook':'ezbook',
        'p13n':  'jajp-princess-p13n',
        'www':   'jajp-princess',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'prod',
        'name':  '.onelink-translations.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    'jp-uat': {
        'book':  'jp-uatbook',
        'ezbook':'uatezbook',
        'p13n':  'jp-uatp13n',
        'www':   'jp-uatwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'uat',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'jp': {
        'book':  'jp-book',
        'ezbook':'ezbook',
        'p13n':  'jp-p13n',
        'www':   'jp-www',
        'api':   'api1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.princess.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    // AU English
    'enau-princess-uat': {
        'book':  'enau-princess-uatbook',
        'ezbook':'enau-princess-uatezbook',
        'p13n':  'enau-princess-uatp13n',
        'www':   'enau-princess-uat',
        'api':   'qaapi1',
        'gwapi': 'gw.api',
        'envir': 'uat',
        'name':  '.onelink-translations.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'enau-princess': {
        'book':  'enau-princess-book',
        'ezbook':'ezbook',
        'p13n':  'enau-princess-p13n',
        'www':   'enau-princess',
        'api':   'qaapi1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.onelink-translations.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    'au-uat': {
        'book':  'uatbook',
        'ezbook':'uatezbook',
        'p13n':  'au-uatp13n',
        'www':   'au-uatwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'uat',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'au': {
        'book':  'book',
        'ezbook':'ezbook',
        'p13n':  'au-p13n',
        'www':   'au-www',
        'api':   'api1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.princess.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    // GB English
    'engb-princess-uat': {
        'book':  'engb-princess-uatbook',
        'ezbook':'engb-princess-uatezbook',
        'p13n':  'engb-princess-uatp13n',
        'www':   'engb-princess-uat',
        'api':   'qaapi1',
        'gwapi': 'gw.api',
        'envir': 'uat',
        'name':  '.onelink-translations.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    // Onelink Test Prod
    'engb-princess': {
        'book':  'engb-princess-book',
        'ezbook':'ezbook',
        'p13n':  'engb-princess-p13n',
        'www':   'engb-princess',
        'api':   'qaapi1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.onelink-translations.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    'gb-uat': {
        'book':  'uatbook',
        'ezbook':'uatezbook',
        'p13n':  'gb-uatp13n',
        'www':   'gb-uatwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'uat',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-921107e36709-development.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'gb': {
        'book':  'book',
        'ezbook':'ezbook',
        'p13n':  'gb-p13n',
        'www':   'gb-www',
        'api':   'api1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.princess.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENcec641dad58b44e4befb27cd0b991462.min.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENdd6df9f11919488f9d111b8035fd7414.min.js',
    },
    // AWS TEST ENVIRONMENT
    'int-qaapi4': {
        'book':  'stagebook',
        'ezbook':'stageezbook',
        'p13n':  'stagep13n',
        'www':   'stagewww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'stage',
        'name':  '.princess.com',
        'pclClientId': 'a777f4065b5df90d91400b6ff006051e',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-6fb49ce22124-development.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
       // AEM DEV ENV
    'author-p107522-e1006288': {
        'book':  'devbook',
        'ezbook':'devezbook',
        'p13n':  'devp13n',
        'www':   'devwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'dev',
        'name':  '.princess.com',
        'pclClientId': '37436d81abdad802b2c8bb69fad36ffb',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENda8aaae4efb64d7e9edb06e5e20c502d-development.js',
        'pclOnetrustLibrary': '//cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d-test',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'publish-p107522-e1006288': {
        'book':  'devbook',
        'ezbook':'devezbook',
        'p13n':  'devp13n',
        'www':   'devwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'dev',
        'name':  '.princess.com',
        'pclClientId': '37436d81abdad802b2c8bb69fad36ffb',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENda8aaae4efb64d7e9edb06e5e20c502d-development.js',
        'pclOnetrustLibrary': '//cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d-test',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    // AEM QA ENV
    'author-p107522-e1006306': {
        'book':  'devbook',
        'ezbook':'devezbook',
        'p13n':  'devp13n',
        'www':   'devwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'dev',
        'name':  '.princess.com',
        'pclClientId': '37436d81abdad802b2c8bb69fad36ffb',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENda8aaae4efb64d7e9edb06e5e20c502d-development.js',
        'pclOnetrustLibrary': '//cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d-test',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'publish-p107522-e1006306': {
        'book':  'devbook',
        'ezbook':'devezbook',
        'p13n':  'devp13n',
        'www':   'devwww',
        'api':   'qaapi1',
        'gwapi': 'gw.qa-api',
        'envir': 'dev',
        'name':  '.princess.com',
        'pclClientId': '37436d81abdad802b2c8bb69fad36ffb',
        'pclLaunchLibrary': '//assets.adobedtm.com/launch-ENda8aaae4efb64d7e9edb06e5e20c502d-development.js',
        'pclOnetrustLibrary': '//cdn.cookielaw.org/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d-test',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    // AEM STAGE ENV
    'author-p107522-e1006315': {
        'book':  'uatbook',
        'ezbook':'uatezbook',
        'p13n':  'uatp13n',
        'www':   'uatwww',
        'api':   'qaapi1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.princess.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-6fb49ce22124-development.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/pcl/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },
    'publish-p107522-e1006315': {
        'book':  'uatbook',
        'ezbook':'uatezbook',
        'p13n':  'uatp13n',
        'www':   'uatwww',
        'api':   'qaapi1',
        'gwapi': 'gw.api',
        'envir': 'prod',
        'name':  '.princess.com',
        'pclClientId': '32e7224ac6cc41302f673c5f5d27b4ba',
        'pclLaunchLibrary': '//assets.adobedtm.com/5a10ec9b8fa3/3fe673576081/launch-6fb49ce22124-development.js',
        'pclOnetrustLibrary': '/js/global/oneTrust/pcl/scripttemplates/otSDKStub.js',
        'pclOnetrustDomain': '68d2391b-3517-4451-9880-5e789ba1952d',
        'onesourceLaunchLibrary': '//assets.adobedtm.com/launch-ENc9d9cc55c97b425f961471e0961ecd8b-development.min.js',
    },

    'prodSubDomains': /ezbook|book|www/i,

    'updateSubdomain': function ($container) {
        $container.find('a').each(function () {
            $.each(this.attributes, function () {
                if (this.specified && (this.name === 'href' || this.name.indexOf('url') > -1)) {
                    var targetSubDomain = this.value.match(hostDomain.prodSubDomains);

                    if (targetSubDomain !== null) {
                        var internalUrl = this.value.replace(targetSubDomain[0], hostDomain[sub_domain_substring][targetSubDomain[0]]);
                        this.value = internalUrl;
                    }
                }
            });
        });
    },

    'localizeSubDomain': function($container) {
        $container.find('a').each(function () {
            $.each(this.attributes, function () {
                if (this.specified && this.name === 'data-language-select') {
                    var dataLanguage = this.value.split('|'),
                        languageCode = dataLanguage[0],
                        uatCode = dataLanguage[1],
                        hostSubDomain = !host_name.match(hostDomain.prodSubDomains) ? 'www' : host_name.match(hostDomain.prodSubDomains)[0],
                        environment = hostDomain[sub_domain_substring]['envir'],
                        targetSubDomain,
                        internalUrl,
                        transaltionUat = ['esla','jajp','enau'];

                    if (environment === 'dev' || environment === 'stage' || environment === 'uat') {
                        // ex output: esla-princess-uat vs es-uat
                        targetSubDomain = (transaltionUat.indexOf(uatCode) > -1) ? uatCode + '-princess-uat' : uatCode;
                    } else {
                        // ex output: es,jp
                        targetSubDomain = languageCode == 'en' ? environment : languageCode;
                    }
                    // construct url
                    internalUrl = 'https://' + hostDomain[targetSubDomain][hostSubDomain] + hostDomain[targetSubDomain].name + window.location.pathname;
                    // update href val
                    this.ownerElement.href = internalUrl;
                    // check for matching host names
                    if (host_name == this.ownerElement.hostname) {
                        // add active class for current language anchor
                        this.ownerElement.classList.add('active');
                        this.ownerElement.setAttribute('aria-selected', true);
                    }
                }
            });
        });
    }
}

// global url vars used on princess.com
var $bookURL    = 'https://' + hostDomain[sub_domain_substring].book + hostDomain[sub_domain_substring].name,
    $wwwURL     = 'https://' + hostDomain[sub_domain_substring].www + hostDomain[sub_domain_substring].name,
    $swwwURL    = 'https://' + hostDomain[sub_domain_substring].www + hostDomain[sub_domain_substring].name,
    $p13nURL    = 'https://' + hostDomain[sub_domain_substring].p13n + hostDomain[sub_domain_substring].name,
    $bookPCLURL = 'https://' + hostDomain[sub_domain_substring].book + '.princess.com',
    $wwwPCLURL  = 'https://' + hostDomain[sub_domain_substring].www + '.princess.com',
    $swwwPCLURL = 'https://' + hostDomain[sub_domain_substring].www + '.princess.com',
    $apiURL = 'https://' + hostDomain[sub_domain_substring].api + '.princess.com' + (hostDomain[sub_domain_substring].envir === 'prod' ? '' : (hostDomain[sub_domain_substring].envir === 'dev' ? '/dev' : '/stage')),
    $gwapiURL = 'https://' + hostDomain[sub_domain_substring].gwapi + '.princess.com/pcl-web/' + (hostDomain[sub_domain_substring].envir === 'prod' ? 'internal' : ((hostDomain[sub_domain_substring].envir === 'dev') ? 'dev' : 'stage')),
    $pclClientId = hostDomain[sub_domain_substring].pclClientId,
    $dbSvcURL = sub_domain_substring[0] === 'dev' ? 'https://devwww.polarres.com' : $bookURL;

// AEM Environment Mapping

var $aemEnvURL = '';

if (sub_domain_substring[0] === 'dev') {
    $aemEnvURL = 'https://publish-p107522-e1006288.adobeaemcloud.com/';
} else if (sub_domain_substring[0] === 'stage') {
    $aemEnvURL = 'https://publish-p107522-e1006315.adobeaemcloud.com/';
} else {
    $aemEnvURL = 'https://publish-p107522-e1006297.adobeaemcloud.com/';
}
