/*
 * SVG Library Load Script
 */

(function(){
  const srcDomain = $bookURL === window.location.origin ? $bookURL : $wwwURL;
  const svgLibarySrc = `${srcDomain}/images/global/svg-library.svg`;

  fetch(svgLibarySrc)
  .then(response => response.text())
  .then(svgLibrary => {
    let libaryWrapper = document.createElement('div');
    libaryWrapper.className = 'svg-library-wrapper';
    libaryWrapper.innerHTML = DOMPurify.sanitize(svgLibrary);
    document.body.insertBefore(libaryWrapper, document.body.childNodes[0]);
  })
  .catch((error) => {
    console.error('SVG Library', error);
  });
})();