window.guestInfo = {

    /**
     * debug - control the printing of logs in console
     * @param {boolean} setDebug - optional boolean to toggle debug on / off
     */
    debug: (setDebug) => {
        const guestInfoDebug = guestInfo.getStorageName('pcl-guestinfo-debug');
        if (typeof(setDebug) === 'boolean') {
            localStorage.setItem(guestInfoDebug, setDebug);
            console.log('guestInfo:', guestInfoDebug, setDebug);
        }
        return !!localStorage.getItem(guestInfoDebug) ? JSON.parse(localStorage.getItem(guestInfoDebug)) : false;
    },

    /**
     * [currentDate] - new date object based on current time
     * @type {Date}
     */
    currentDate: new Date(),

    // {config} - Object having properties & method  
    'config': {
        'ttl': 60 * 60 * 24 * 730, // [ttl]  2 years in seconds
        'expiry': () => Math.floor(guestInfo.currentDate.getTime() / 1000) + guestInfo.config.ttl, // [expiry] will be (currentDate + ttl)
        'env': hostDomain[sub_domain_substring[0]].envir, // env - current environment
        'eccn': null,
        'sessionID': window?.digitalData?.page?.sessionID, // get the sessionID stored in `pcl-guestSession` Cookie
        'pclGuestStorageName': () => guestInfo.getStorageName('pcl-guest') // get name of pcl-guest Cookie or Local Storage
    },

    // getStorageName - Fetch name of the new `pcl-guest` Cookie and Local Storage
    getStorageName: (name) => {
        return guestInfo.config.env === 'prod' ? name : `${name}-${guestInfo.config.env}`;
    },

    // parsedCookieData - Fetch parsed object from the Cookie
    parsedCookieData: (name) => {
        if (typeof docCookies === 'object' && docCookies.hasItem(name)) {
            return JSON.parse(docCookies.getItem(name));
        }
    },

    // parsedLocalStorageData - Fetch parsed object from the local storage
    parsedLocalStorageData: (name) => {
        if (typeof localStorage === 'object' && localStorage.getItem(name) !== null) {
            return JSON.parse(localStorage.getItem(name));
        }
    },

    /**
     * [expireDatestamp] - sets the expiration date for cookie. 
     * @return {[date object]} - 2 year from timestamp
     */
    expireDatestamp: () => {
        return new Date(guestInfo.currentDate.getFullYear() + 2, guestInfo.currentDate.getMonth(), guestInfo.currentDate.getDate(), guestInfo.currentDate.getHours(), guestInfo.currentDate.getMinutes());
    },

    /**
     * [fetchData] - Using Fetch call to get data 
     * @param {string} endpoint - endpoint url 
     */
    fetchData: (endpoint) => {
        return fetch(endpoint, {
                method: 'GET',
                headers: {
                    'pcl-client-id': $pclClientId,
                    'Accept': '*/*',
                    'Content-Type': 'application/json',
                    'BookingCompany': 'PC',
                    'ProductCompany': 'PC',
                    'appid': JSON.stringify({
                        'userId': 'personalization',
                        'env': guestInfo.config.env
                    })
                }
            })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
            }).then((data) => {
                return data;
            }).catch((err) => {
                return console.log(err);
            });
    },

    /**
     * Check the presence of different cookies/local storage
     * Fetch ECCN, store it in Config Obj and run relevant function
     */
    isUserKnown: () => {
        let pclGuestCookieECCN, pclGuestLocalStorageECCN, pclCpGuestECCN;
        // Fetch name of new cookie and local storage `pcl-guest`
        const pclGuestStorageName = guestInfo.config.pclGuestStorageName();
        // Parse the data from the `pcl-guest` cookie and store ECCN in it.
        const pclGuestCookieObj = guestInfo.parsedCookieData(pclGuestStorageName);
        if (!!pclGuestCookieObj && !!pclGuestCookieObj.eccn) {
            pclGuestCookieECCN = pclGuestCookieObj.eccn;
        }
        // Parse the data from the `pcl-guest` local storage and store ECCN in it.
        const pclLocalStorageObj = guestInfo.parsedLocalStorageData(pclGuestStorageName);
        if (!!pclLocalStorageObj && !!pclLocalStorageObj.eccn) {
            pclGuestLocalStorageECCN = pclLocalStorageObj.eccn;
        }
        
		// ECCN from pcl_cpguest cookie, when user logins via CP with booking number
		if (docCookies.hasItem('pcl_cpguest')) {
			pclCpGuestECCN = docCookies.getItem('pcl_cpguest');
		}
		
        /**
		 * 1. Check if ECCN present in `pcl-guest` cookie doesn't match with ECCN present in `pcl_cpguest` cookie, then update ECCN in config obj and call API
         * 2. Check if ECCN is present in both `pcl-guest` cookie and local storage, then update ECCN in config obj and check for stale data or call API
         * 3. Check if ECCN is present in either `pcl-guest` cookie or local storage, then update ECCN in config obj and call API
         * 4. Check if ECCN is present in `sccn` cookie, then update ECCN in config obj and call API
         * 5. Check if ECCN is present  in `pcl_cpguest` cookie, then update ECCN in config obj and call API
         */
		 
		if (
            window.location.pathname === '/manage-booking/summary.page'
            && !!pclGuestCookieECCN
            && !!pclCpGuestECCN
            && pclCpGuestECCN!== pclGuestCookieECCN
            ) {
			guestInfo.config["eccn"] = pclCpGuestECCN;
			// call personalization API 
            guestInfo.callPersonalizationAPI();
		} else if (!!pclGuestCookieECCN && !!pclGuestLocalStorageECCN) {
            guestInfo.config["eccn"] = pclGuestCookieECCN;
            // if ECCN matches call stale data check function, otherwise call API
            pclGuestCookieECCN === pclGuestLocalStorageECCN ? guestInfo.staleDataCheck(pclGuestCookieObj, pclLocalStorageObj) : guestInfo.callPersonalizationAPI();
        } else if (!!pclGuestCookieECCN || !!pclGuestLocalStorageECCN) {
            guestInfo.config['eccn'] = !!pclGuestCookieECCN ? pclGuestCookieECCN : pclGuestLocalStorageECCN;
            // call personalization API 
            guestInfo.callPersonalizationAPI();
        } else if (docCookies.hasItem('sccn')) {
            guestInfo.config["eccn"] = docCookies.getItem('sccn');
            // call personalization API 
            guestInfo.callPersonalizationAPI();
        } else if (window.location.pathname === '/manage-booking/summary.page' && !!pclCpGuestECCN) {
            guestInfo.config["eccn"] = pclCpGuestECCN;
            // call personalization API 
            guestInfo.callPersonalizationAPI();
        }
    },

    /**
     * `pcl-guest` cookie fetch sessionID & timestamp
     * `pcl-guest` local storage fetch sessionID & timestamp
     */
    staleDataCheck: (pclGuestCookieObj, pclLocalStorageObj) => {
        // Parse the data from the `pcl-guest` cookie and store sessionID and timeStamp to compare
        let pclGuestSessionID, pclGuestTimestamp;
        if (!!pclGuestCookieObj && pclGuestCookieObj.sessionID) {
            pclGuestSessionID = pclGuestCookieObj.sessionID;
        }
        if (!!pclGuestCookieObj && pclGuestCookieObj.timestamp) {
            pclGuestTimestamp = pclGuestCookieObj.timestamp;
        }
        guestInfo.debug() && console.log('Personalization - stale data check - check pcl-guest cookie properties sessionID, timestamp ', pclGuestSessionID, pclGuestTimestamp);

        // Parse the data from the local storage `pcl-guest` and store sessioID and timestamp to compare
        let localStorageSessionID, localStorageTimestamp;
        if (!!pclLocalStorageObj && pclLocalStorageObj.sessionID) {
            localStorageSessionID = pclLocalStorageObj.sessionID;
        }
        if (!!pclLocalStorageObj && pclLocalStorageObj.timestamp) {
            localStorageTimestamp = pclLocalStorageObj.timestamp;
        }
        guestInfo.debug() && console.log('Personalization - stale data check - check pcl-guest local storage properties sessionID, timestamp', localStorageSessionID, localStorageTimestamp);

        // staleTimestamp formula: (Date.now - 1 Day) / 1000 == a timestamp 1 day ago in seconds
        const staleTimestamp = (Date.now() - 1000 * 60 * 60 * 24) / 1000;

        // Compare the sessionID present in the pcl-guest cookie, pcl-guest local storage, pcl-guestSession 
        if ((pclGuestSessionID !== localStorageSessionID) || (pclGuestSessionID !== guestInfo.config.sessionID) || (localStorageSessionID !== guestInfo.config.sessionID)) {
            guestInfo.debug() && console.log('Personalization - stale data check - check sessionID pcl-guest cookie, localStorage, guestInfo.config.sessionID', pclGuestSessionID, localStorageSessionID, guestInfo.config.sessionID);
            guestInfo.callPersonalizationAPI();
            /**
             * Compare the timestamp present in Cookie and Local storage with the  staleTimestamp
             * If the staleTimestamp is greater than either of the timestamp, call Personalization API 
             */
        } else if ((staleTimestamp > pclGuestTimestamp) || (staleTimestamp > localStorageTimestamp)) {
            guestInfo.callPersonalizationAPI();
        } else {
            guestInfo.debug() && console.log('Personalization - stale data check - data is not stale - push available data');
            guestInfo.pushAvailableData(pclLocalStorageObj);
        }
    },

    // Push available data to data layer when data is not stale
    pushAvailableData: (pclLocalStorageObj) => {
        if (!!pclLocalStorageObj && pclLocalStorageObj.value) {
            // Check the data layer is present 
            window.digitalData = window.digitalData || {};
            window.digitalData.user = window.digitalData.user || {};
            window.digitalData.user.profile = window.digitalData.user.profile || {};
            window.digitalData.user.profile = guestInfo.updateResponseObj(pclLocalStorageObj.value);
            window.digitalData.user.profile.eccn = window.digitalData.user.profile.eccn || {};
            window.digitalData.user.profile.eccn = guestInfo.config.eccn;
        }
    },

    // Create object and store properties timestamp, eccn, expiry and sessionID
    storageDataObj: () => {
        const storageDataObj = {
            "timestamp": Math.floor(guestInfo.currentDate.getTime() / 1000), // This is timestamp when it is created
            "eccn": guestInfo.config.eccn, // fetch ECCN
            "expiry": guestInfo.config.expiry(), // expiry
            "sessionID": guestInfo.config.sessionID // sessionID pulled from the cookie pcl-guestSession
        };
        return storageDataObj;
    },

    // Create Local Storage `pcl-guest` 
    createPclLocalStorage: () => {
        // call the storageDataObj function to fetch the data
        const storageDataObj = guestInfo.storageDataObj();
        storageDataObj["ttl"] = guestInfo.config.ttl;
        guestInfo.debug() && console.log('Personalization - pcl-guest local storage object', JSON.stringify(storageDataObj));
        // getting the storage key name
        const storageKeyName = guestInfo.config.pclGuestStorageName();
        // store the response in local storage
        localStorage.setItem(storageKeyName, JSON.stringify(storageDataObj));
    },

    // Create cookie `pcl-guest`
    createPclGuestCookie: () => {
        // call the storageDataObj function to fetch the data
        const storageDataObj = guestInfo.storageDataObj();
        guestInfo.debug() && console.log('Personalization - pcl-guest cookie object', JSON.stringify(storageDataObj));
        // store data into the session cookie 
        const pclGuestCookie = guestInfo.config.pclGuestStorageName();
        docCookies.setItem(pclGuestCookie, JSON.stringify(storageDataObj), guestInfo.expireDatestamp(), '/', 'princess.com', true);
    },

    //  Check if the value is Number
    convertToNumber: (value) => {
        return Number(value).toString() !== 'NaN' ? Number(value) : value;
    },

    // Check date format
    dateObjectFormat: (date) => {
        return new Date(date).getTime().toString() !== 'NaN' && Number(date).toString() === 'NaN' ? new Date(date) : date;
    },

    // Update JSON response from the API call 
    updateResponseObj: (responseObj) => {
        for (const p in responseObj) {
            if (responseObj[p] !== null) {
                if (responseObj[p] !== false && responseObj[p] !== true && !(responseObj[p] instanceof Date)) {
                    responseObj[p] = guestInfo.convertToNumber(responseObj[p]);
                }
                responseObj[p] = responseObj[p] === 'Y' ? true : responseObj[p] === 'N' ? false : responseObj[p];
                responseObj[p] = guestInfo.dateObjectFormat(responseObj[p]);

            }
            if (p === 'interactions' && typeof responseObj[p] === 'object') {
                for (const k in responseObj[p]) {
                    if (responseObj[p][k] !== null) {
                        if (responseObj[p][k] !== false && responseObj[p][k] !== true && !(responseObj[p][k] instanceof Date)) {
                            responseObj[p][k] = guestInfo.convertToNumber(responseObj[p][k]);
                        }
                        responseObj[p][k] = guestInfo.dateObjectFormat(responseObj[p][k]);
                    }
                    if (typeof responseObj[p][k] === 'object' && responseObj[p][k] !== null && !Array.isArray(responseObj[p][k])) {
                        for (const n in responseObj[p][k]) {
                            if (responseObj[p][k][n] !== null) {
                                if (responseObj[p][k][n] !== false && responseObj[p][k][n] !== true && !(responseObj[p][k][n] instanceof Date)) {
                                    responseObj[p][k][n] = guestInfo.convertToNumber(responseObj[p][k][n]);
                                }
                                responseObj[p][k][n] = guestInfo.dateObjectFormat(responseObj[p][k][n]);
                            }
                        }
                    }
                }
            }
            if ((p === 'preferredTrades' || p === 'brochures') && Array.isArray(responseObj[p])) {
                responseObj[p].forEach(data => {
                    for (const k in data) {
                        if (data[k] !== null) {
                            if (responseObj[k] !== false && responseObj[k] !== true && !(responseObj[k] instanceof Date)) {
                                data[k] = guestInfo.convertToNumber(data[k]);
                            }
                            data[k] = guestInfo.dateObjectFormat(data[k]);
                        }
                    }
                })
            }
        }
        return responseObj;
    },

    // Call to fetch the latest Response
    callPersonalizationAPI: () => {
        // Check the Data Layer Existence 
        window.digitalData = window.digitalData || {};
        window.digitalData.user = window.digitalData.user || {};
        window.digitalData.user.profile = window.digitalData.user.profile || {};

        const endpoint = `${$gwapiURL}/crm-wm/p1.0/personalization/${guestInfo.config.eccn}?ProductCompanyCode=PC&BookingCompanyCode=PC`;
        const jsonResponse = guestInfo.fetchData(endpoint);
        jsonResponse.then((response) => {
            // Update the response fetched from CRM API call 
            const crmResponse = response.Contact;
            const updatedResponse = guestInfo.updateResponseObj(crmResponse);
            guestInfo.debug() && console.log('Personalization - CRM API call', JSON.stringify(updatedResponse));

            // Call function for `pcl-guest` local storage to load
            guestInfo.createPclLocalStorage();
            // Update the local storage with response and save back to local storage
            const pclGuestLocalStorageName = guestInfo.config.pclGuestStorageName();
            const localStorageObj = guestInfo.parsedLocalStorageData(pclGuestLocalStorageName);
            localStorageObj['value'] = updatedResponse;
            localStorage.setItem(pclGuestLocalStorageName, JSON.stringify(localStorageObj));

            // Call function for `pcl-guest` cookie to load
            guestInfo.createPclGuestCookie();

            // push guest data into the Data layer
            window.digitalDataHelper?.addData('user', 'profile', updatedResponse);
            window.digitalData.user.profile['eccn'] = guestInfo.config.eccn;

            // Assign and trigger event
            window.dispatchEvent(new CustomEvent('guestInfo', {
                bubbles: true,
                detail: {data : updatedResponse}
            }));

        }).catch((error) => {
            // call the storageDataObj function to fetch the data 
            const storageDataObj = guestInfo.storageDataObj();
            storageDataObj['expiry'] = Math.floor(guestInfo.currentDate.getTime() / 1000) + (60 * 30);

            // store data into the session cookie 
            const guestCookieName = guestInfo.config.pclGuestStorageName();
            docCookies.setItem(guestCookieName, JSON.stringify(storageDataObj), guestInfo.expireDatestamp(), '/', 'princess.com', true);

            // push guest data into the Data layer
            window.digitalDataHelper?.addData('user', 'profile', storageDataObj);
        });
    }
}
window.guestInfo.isUserKnown();