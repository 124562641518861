/**
 * Regex to return locale attributes
 * @param {*} localizedLink
 * @param {*} attribute
 * @returns
 */
window.getAttributesFromLink = (localizedLink, attributes) => {
    const localeCode = (window.localeData?.status)?.toLowerCase() || 'in';
    const attrsData = [];
    const getAttributeValue = (attributes, attribute) => {
        const attr = attributes?.find(el => el.indexOf(attribute) >= 0);
        const attrValue = attr?.split(`${attribute}=`)[1]?.replaceAll('"', '');
        return attrValue;
    }
    attributes.forEach((attribute) => {
        const attrs = localizedLink?.match(/[\w-]+="[^"]*"/g);
        const attr = getAttributeValue(attrs, attribute);
        const excludeCountries = getAttributeValue(attrs, 'data-exclude');
        const isEu = new Array(getAttributeValue(attrs, 'data-use-iseu'));
        if (excludeCountries != undefined && excludeCountries.includes(localeCode)) {
            return;
        }
        // Support for EU Locale - data-text
        if (window.localeData?.isEU === 'true' && isEu[0] === 'true' && attribute.endsWith('-text')) {
            const attr = getAttributeValue(attrs, "data-eu-text");
            attrsData.push(attr);
            return;
        }
        // Support for EU Locale - data-url
        if (window.localeData?.isEU === 'true' && isEu[0] === 'true' && attribute.endsWith('-url')) {
            const attr = getAttributeValue(attrs, "data-eu-url");
            attrsData.push(attr);
            return;
        }
        if (attr) {
            attrsData.push(attr);
            return;
        }
        // Support for international locale - data-text
        if (attribute.endsWith('-text')) {
            const attr = getAttributeValue(attrs, "data-text");
            attrsData.push(attr);
            return;
        }
        // Support for international locale - data-url
        if (attribute.endsWith('-url')) {
            const attr = getAttributeValue(attrs, "data-url");
            attrsData.push(attr);
            return;
        }
    });
    return attrsData;
}

/**
 * Converts String to HTML
 * @param {*} str 
 * @returns 
 */
window.convertStringToHTML = (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    return doc.body;
};

/**
 * Converts String to kebabcase
 * @param {*} str
 * @returns
 */
window.convertStringToKebabCase = string => string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

/*
 * Accepts the container element as input parameter. Finds all language links in the container element and updates link URL as per domain and page URL.
 * @param {*} container HTMLElement
 */
window.setupLanguageSelector = (container) => {
    container.querySelectorAll('a').forEach((languageLink) => {
        const languageSelect = languageLink.getAttribute('data-language-select'),
            dataLanguage = languageSelect.split('|'),
            languageCode = dataLanguage[0],
            uatCode = dataLanguage[1],
            hostSubDomain = !host_name.match(hostDomain.prodSubDomains) ? 'www' : host_name.match(hostDomain.prodSubDomains)[0],
            environment = hostDomain[sub_domain_substring]['envir'],
            transaltionUat = ['esla', 'zhcn', 'jajp', 'zhtw', 'enau'];
        
        let targetSubDomain;
        if (environment === 'dev' || environment === 'stage' || environment === 'uat') {
            targetSubDomain = (transaltionUat.indexOf(uatCode) > -1) ? uatCode + '-princess-uat' : uatCode;
        } else {
            targetSubDomain = languageCode == 'en' ? environment : languageCode;
        }
        // construct url
        const internalUrl = 'https://' + hostDomain[targetSubDomain][hostSubDomain] + hostDomain[targetSubDomain].name + window.location.pathname;
        // update href val
        languageLink.href = internalUrl;
    });
}

/**
 * getViewportDimensions to retrive viewport dimensions
 * @returns viewport width and height
 */
window.getViewportDimensions = () => {
    return {
        width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
        height: Math.max(document.documentElement.clientHeight || 0, window.clientHeight || 0)
    }
}

/**
 * examples
 * timezone.getPacificTimezone(new Date()),       // [str]  PDT
 * timezone.getConvertedDateTime(new Date()),     // [obj]  Mon Aug 12 2019 23:35:00 GMT-0700 (Pacific Daylight Time)
 * timezone.stdTimezoneOffset(new Date()),        // [int]  480
 * timezone.isDstObserved(new Date()),            // [bool] true
 */
const timezone = {
    /**
     * getPacificTimezone
     * @param  {Date} date - date object
     * @return {string} pacific time zone
     */
    getPacificTimezone: function (date) {
        return this.isDstObserved(date) ? "PDT" : "PST";
    },
    /**
     * getConvertedDateTime
     * @param  {string} date - date string to be converted into date object
     * @return {Date} modified date object with pacific timezone
     */
    getConvertedDateTime: function (date) {
        return new Date(date + " " + this.getPacificTimezone(new Date()));
    },
    /**
     * stdTimezoneOffset
     * @param  {Date} date - date object
     * @return {int} standard timezone offset
     */
    stdTimezoneOffset: function (date) {
        const jan = new Date(date.getFullYear(), 0, 1),
            jul = new Date(date.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    },
    /**
     * isDstObserved
     * @param  {Date}  date - date object
     * @return {boolean} is daylight savings observed
     */
    isDstObserved: function (date) {
        return date.getTimezoneOffset() < this.stdTimezoneOffset(date);
    }
};

window.linkStylesheet = url => new Promise((resolve, reject) => {
    let link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.onload = () => resolve();
    link.onerror = () => reject();
    link.href = url;

    let headScript = document.querySelector('script');
    headScript.parentNode.insertBefore(link, headScript);
});

