/*
 * Deals Pricing Plugin to display dynamic pricing based on itinerary parameters provide
 */
(() => {
    //dynamicPricing
    window.dynamicPricing = (products, options) => {

        /**
         * Default Settings
         * @param  {object} products - all links that will receive dynamic pricing
         * @param  {date} currentTimestamp - date object of current time
         * @param  {boolean} debug - prints extra information in console
         * @param  {string} endTimestamp - page level timestamp, or can be link specific timestamp using data-pricing-end. Page level will override link check. No timestamp runs always. Format needs to be YYYY-MM-DDTHH:MM:SS-0700 (or -0800)
         * @param  {string} filters - ????
         * @param  {string} getPricing - attribute (data-pricing) to indicate if link should have dynamic pricing
         * @param  {string} locale - page level locale, or can be link specific locale using data-pricing-country. Page level will override link check. No locale will stop dynamic pricing. List of country(ies) can be provided comma separated, ex. US,CA
         * @param  {string} meta - attribute (data-pricing-meta) to control display of meta for each link, true will display. 
         * @param  {string} startTimestamp - page level timestamp, or can be link specific timestamp using data-pricing-start. Page level will override link check. No timestamp runs always. Format needs to be YYYY-MM-DDTHH:MM:SS-0700 (or -0800)
         * @param  {string} target - css selector to place dynamic pricing results, used when link include more than one line of text
         * @param  {string} template - attribute for pricing template - customizable
         * @param  {string} type - per-diem vs cruise fare
         * @param  {string} userCountry - user's country from localeData object
         * @param  {string} voyageCurrency - css selector to update disclaimer with user's currency
         * Not customizable settings
         * @param  {string} index - attribute (data-pricing-index) for index of links on page - not customizable
         * @param  {boolean} pageLocaleMatch - stores page locale match status. When undefined, fallback to link level check - not customizable
         * @param  {boolean} pageTimeCheck - stores page level timecheck. When undefined, fallback to link level check - not customizable
         * @param  {string} status - attribute (data-pricing-status) for giving final status on link
        */
        const settings = {
            products: products,
            currentTimestamp: new Date(),
            debug: hostDomain[sub_domain_substring[0]].envir !== 'prod',
            endTimestamp: 'data-pricing-end',
            filters: 'data-pricing-filters',
            getPricing: 'data-pricing',
            country: 'data-pricing-country',
            meta: 'data-pricing-meta',
            showBrochureFare: 'data-brochure-fare',
            startTimestamp: 'data-pricing-start',
            target: '.pricing',
            template: 'data-pricing-template',
            type: 'data-pricing-type',
            userCountry: undefined,
            voyageCurrency: '.voyage-currency'
            , ...options
        };

        // private settings that should not be overriden by plugin config
        settings.pageLocaleMatch = undefined;
        settings.pageTimeCheck = undefined;
        settings.index = 'data-pricing-index';
        settings.status = 'data-pricing-status';

        if (settings.debug) {
            console.log('dynamicPricing Options:', settings);
        }

        /**
         * status - set the pricing status in the dom, and print to console for debugging
         * @param {object} product 
         * @param {boolean} setStatus - when set to true, update data-pricing-status
         * @param {string} message - message to display in data-pricing-status / console.log
         * @param {string, object, array, boolean} data - data to show in console.log
         */
        const dynamicPricingStatus = (product, setStatus, message, data) => {
            if (product && setStatus) {
                product.setAttribute(settings.status, message);
                window.dispatchEvent(new CustomEvent('dynamicPricing.status', {
                    bubbles: true,
                    detail: {
                        productEl: product,
                        message: message,
                        data: data
                    }
                }));
            }
            if (settings.debug) {
                console.log('dynamicPricing ' + product.getAttribute(settings.index) + ':', message, data);
            }
        }

        /**
         * paramsString - converts the object of parameters back to a string
         * @param  {object} paramsObj - parameters for the current itinerary
         * @return {string}           
         */
        const paramsString = (paramsObj) => {
            const params = new URLSearchParams(paramsObj);
            return params.toString();
        }

        /**
         * logPricing - log pricing relate info in splunk
         * @param  {object} paramsObj - params as an object
         * @param  {string} status    - pricing status (ie. invalid)
         * @param  {string} message   - details on the log
         
        const logPricing = async (paramsObj, status, message) => {
            //set the target url
            const targetUrl = `${window.$gwapiURL}/guest/p1.0/ui-logs`;

            // set the payload
            const payload = { title: 'deals pricing error', detail: message, status: status, source: 'params:' + paramsString(paramsObj) + ', pagename:' + window.digitalData.page.subdomain + ':' + window.digitalData.page.pathname }

            const requestHeader = {
                'Content-Type': 'application/json',
                'ProductCompany': 'PC',
            } 
            switch (paramsObj.country) {
                case 'GB':
                case 'UK':
                    headers.BookingCompany = 'PO';
                    break;
                case 'AU':
                case 'NZ':
                    headers.BookingCompany = 'PA';
                    break;
                default:
                    headers.BookingCompany = 'PC';
            }

            const requestOptions = {
                method: 'POST',
                headers: requestHeader,
                body: JSON.stringify([payload])
            };

            await fetch(targetUrl, requestOptions)
                .catch(error => {
                    console.error('There was an error logPricing', error);
                });
        }
        */

        /**
         * paramsObj - converts the query sting from the product href into an object
         * @param  {object} product - an individual itinerary (ie. group of voyages)
         * @return {object}
         */
        const paramsObj = (product) => {
            const params = product.tagName === 'A' ? product.getAttribute('href').split('?')[1] : product.getAttribute(settings.filters);
            let paramsObject = {};

            // check if params exist to prevent undefined error
            if (params === undefined) {
                dynamicPricingStatus(product, true, 'missing filter parameters', paramsObject);
                return false;
            } else {
                params.split('&').forEach(function (x) {
                    const arr = x.split('=');
                    if (arr[1]) {
                        // add the parameter when there is a value
                        paramsObject[arr[0]] = arr[1];
                    } else {
                        // warn when parameter is missing value
                        dynamicPricingStatus(product, false, 'empty parameter', arr[0]);
                    }
                });
                // assign meta if not set, default is Interior
                if (!paramsObject.meta) {
                    paramsObject.meta = 'I';
                }
                // assign user's country if not set
                if (!paramsObject.country) {
                    paramsObject.country = localeData.country;
                }
                // assign user's air city if not set
                if (!paramsObject.homeCity) {
                    paramsObject.homeCity = !!localeData.aircity ? localeData.aircity : localeData.defaultHomeCity;
                }
                dynamicPricingStatus(product, false, 'paramsObj', paramsObject);
                return paramsObject;
            }
        }

        /**
         * timeCheck - check timestamps to see if dynamic pricing can be applied. when no dates are availble, assume there is no timesensitivity to the link(s) on current page
         * @param {object} product - individual link
         * @return {boolean / null} - true if timing is good, false when bad. null is returned if no date comparison
         */
        const timeCheck = (product) => {
            let startTimestamp, start, endTimestamp, end, timeCheck;

            switch (settings.pageTimeCheck) {
                case undefined:  // page time check on first call
                    startTimestamp = (settings.startTimestamp === 'data-pricing-start') ? undefined : timezone.getConvertedDateTime(settings.startTimestamp);
                    start = (!!startTimestamp) ? startTimestamp < settings.currentTimestamp : null;
                    endTimestamp = (settings.endTimestamp === 'data-pricing-end') ? undefined : timezone.getConvertedDateTime(settings.endTimestamp);
                    end = (!!endTimestamp) ? settings.currentTimestamp < endTimestamp : null;

                    if (start === null && end === null) {
                        timeCheck = settings.pageTimeCheck = null;
                    } else {
                        if (start !== null && end !== null) {
                            // start and end timestamps are set, make sure both are true
                            timeCheck = settings.pageTimeCheck = start && end;
                        } else {
                            // only one timestamp is set, make sure current timestamp is less than end, before checking if current timestamp is greater than start
                            timeCheck = settings.pageTimeCheck = (start !== null) ? start : end;
                        }
                        break;
                    }

                case null: // test timestamps from link
                    startTimestamp = (product.getAttribute(settings.startTimestamp)) ? timezone.getConvertedDateTime(new Date(product.getAttribute(settings.startTimestamp))) : undefined;
                    start = (!!startTimestamp) ? startTimestamp < settings.currentTimestamp : null;
                    endTimestamp = (product.getAttribute(settings.endTimestamp)) ? timezone.getConvertedDateTime(new Date(product.getAttribute(settings.endTimestamp))) : undefined;
                    end = (!!endTimestamp) ? settings.currentTimestamp < endTimestamp : null;

                    if (start === null && end === null) {
                        timeCheck = null;
                    } else {
                        if (start !== null && end !== null) {
                            // start and end timestamps are set, make sure both are true
                            timeCheck = start && end;
                        } else {
                            // only one timestamp is set, make sure current timestamp is less than end, before checking if current timestamp is greater than start
                            timeCheck = (start !== null) ? start : end;
                        }
                    }
                    break;

                default:  // when pageTimeCheck is defined, return to link
                    timeCheck = settings.pageTimeCheck;
            }

            dynamicPricingStatus(product, timeCheck === false, 'time check: ' + timeCheck, { 'startTimestamp': startTimestamp, 'endTimestamp': endTimestamp, 'settings.currentTimestamp': settings.currentTimestamp, 'settings.pageTimeCheck': settings.pageTimeCheck });
            return timeCheck;
        }

        /**
         * dynamicCapsPricing - will make the ajax call to get the pricing from PolarCAPs. When results are available, the data will be used to select the proper meta
         * @param  {object} product - an individual itinerary (ie. group of voyages)
         * @param  {object} paramsObj - the parameters for the current itinerary
         */
        const dynamicCapsPricing = (product, paramsObj) => {
            const paramString = paramsString(paramsObj);
            const targetUrl = hostDomain[sub_domain_substring[0]].envir === 'dev' ? `https://gw.qa-api.princess.com/pcl-web/stage/resdb/p1.0/deals?${paramString}` : `${window.$gwapiURL}/resdb/p1.0/deals?${paramString}`;
            const targetClientId = hostDomain[sub_domain_substring[0]].envir === 'dev' ? `a777f4065b5df90d91400b6ff006051e` : `${window.$pclClientId}`;
            const requestOptions = {
                method: 'GET',
                credentials: 'include',
                headers: {
                    'Content-Type': 'application/json',
                    'appid': '{"userId":"cms","cmsFunction":"dynamic pricing"}',
                    'pcl-client-id': targetClientId,
                    'productcompany': 'PC',
                    'reqsrc': 'W'
                }
            };
            
            switch (paramsObj.country) {
                case 'GB':
                case 'UK':
                    requestOptions.headers.bookingcompany = 'PO';
                    break;
                case 'AU':
                case 'NZ':
                    requestOptions.headers.bookingcompany = 'PA';
                    break;
                default:
                    requestOptions.headers.bookingcompany = 'PC';
            }
            
            fetch(targetUrl, requestOptions)
            .then(response => {
                const contentType = response.headers.get('content-type');
                if (!contentType || !contentType.includes('application/json')) {;
                    throw new TypeError('PolarCAPs not returing pricing.', response);
                }
                return response.json();
            })
            .then(results => {
                if (results.products.length > 0) {
                    // pricing for selected meta
                    selectCapsMeta(product, paramsObj, results);
                } else {
                    // no results status in data attribute
                    dynamicPricingStatus(product, true, 'number of results', results.products.length);
                }
            })
            .catch(error => {
                console.error('There was an error getCapsPricing', error);
            });
        }

        /**
         * localeCheck - check if pricing link is eligible for user's locale, page level or for each individual product link
         * @param  {object} product - an individual pricing link (ie. group of voyages)
         * @return {boolean} - will return match
         */
        const localeCheck = (product) => {
            let pricingLocale, localeMatch;
            switch (settings.pageLocaleMatch) {
                case undefined: // global locale check on first attempt
                    if (settings.country === 'data-pricing-country') {
                        // if a list of countries not provided in settings, to defer to link level check
                        settings.pageLocaleMatch = null;
                    } else {
                        // test list of countries from settings, store global match in settings
                        pricingLocale = settings.country.split(',');
                        localeMatch = settings.pageLocaleMatch = pricingLocale.indexOf(settings.userCountry) > -1

                        break; // only break when list of countries is available, otherwise continue onto the link level check for first attempt
                    }

                case null:
                    // test list of countries from link
                    pricingLocale = !!product.getAttribute(settings.country) ? product.getAttribute(settings.country).split(',') : [];
                    localeMatch = pricingLocale.indexOf(settings.userCountry) > -1;
                    break;

                default: // when pageLocaleMatch is defined, return to link
                    localeMatch = settings.pageLocaleMatch;
            }

            dynamicPricingStatus(product, !localeMatch, 'locale match: ' + localeMatch, { 'pricingLocale': pricingLocale, 'pageLocaleMatch': settings.pageLocaleMatch });
            return localeMatch;
        }

        /**
         * selectCapsMeta - find / display the pricing for the selected meta in the PolarCAPs response. If the selected meta is sold out, try getting pricing for the next meta up
         * @param  {object} product - an individual itinerary (ie. group of voyages)
         * @param  {object} paramsObj  - parameters for the current itinerary
         * @param  {object} pricingObj - JSON data from dynamicPricing
         */
        const selectCapsMeta = (product, paramsObj, pricingObj) => {
            let metas = pricingObj.products[0].cruises[0].pricing.fares[0].metas,
                selectedMetaObj = {},
                pricingStatus;

            // find the paramsObj.meta from list of metas in response
            metas.forEach((meta) => {
                if (meta.id === paramsObj.meta) {
                    selectedMetaObj = meta;

                    // add the meta name to be used in the display
                    switch (meta.id) {
                        case 'I':
                            selectedMetaObj.name = 'Interior';
                            break;
                        case 'O':
                            selectedMetaObj.name = 'Oceanview';
                            break;
                        case 'B':
                            selectedMetaObj.name = 'Balcony';
                            break;
                        case 'M':
                            selectedMetaObj.name = 'Mini-Suite';
                            break;
                        case 'S':
                            selectedMetaObj.name = 'Suite';
                            break;
                    }
                }
            });

            dynamicPricingStatus(product, false, 'selectedMetaObj', selectedMetaObj);

            if (selectedMetaObj.status === 'A') {
                let categories = pricingObj.products[0].cruises[0].pricing.fares[0].categories,
                    selectedCategoryObj;

                categories.forEach((category) => {
                    if (category.id === selectedMetaObj.bestCategory) {
                        selectedCategoryObj = category;
                    }
                });

                dynamicPricingStatus(product, false, 'selectedCategoryObj', selectedCategoryObj);

                const isPerDiem = product.getAttribute(settings.type) === 'per-diem';
                const fare = (isPerDiem) ? selectedCategoryObj.perDiemFare : selectedCategoryObj.guests[0].fare;
                const brochureFare = selectedCategoryObj.guests[0].brochureFare;


                if (fare > 0) {
                    // update url on product to ensure we're linking search results to the correct meta, but remove country & homeCity first!
                    delete paramsObj.country;
                    delete paramsObj.homeCity;
                    window.dynamicPricing.updateUrl(product, paramsString(paramsObj), settings.filters);
                    window.dynamicPricing.displayPricing(product, settings.target, settings.template, fare, brochureFare, isPerDiem, selectedMetaObj.name, settings.meta, settings.showBrochureFare);
                    pricingStatus = 'success';
                } else {
                    pricingStatus = 'invalid';
                }
            } else {
                // selected meta is sold out...
                if (paramsObj.meta === 'S') {
                    // if suites are sold out, means all meta are sold out
                    pricingStatus = 'sold out';
                } else {
                    // try getting pricing for the next meta up
                    switch (paramsObj.meta) {
                        case 'I':
                            paramsObj.meta = "O";
                            break;
                        case 'O':
                            paramsObj.meta = "B";
                            break;
                        case 'B':
                            paramsObj.meta = "M";
                            break;
                        case 'M':
                            paramsObj.meta = "S";
                            break;
                    }
                    // update meta in paramsObj to next level up and then try getting pricing
                    dynamicCapsPricing(product, paramsObj);
                }
            }

            dynamicPricingStatus(product, true, 'pricing status: ' + pricingStatus, pricingStatus);

            // set currency code based on dynamic pricing result
            window.dynamicPricing.voyageCurrency(document.querySelector(settings.voyageCurrency), localeData.primaryCurrency);
        }

        /**
         * init - call the appropriate functions to display static vs dynamic pricing
         * @param  {object} product - an individual itinerary (ie. group of voyages)
         */
        const init = (products) => {
            products.forEach((product, index) => {
                // assign index to better track links on page vs network calls
                product.setAttribute(settings.index, index);

                // run dynamic pricing when locale & time checks pass
                if (localeCheck(product) && timeCheck(product) !== false) {
                    const paramsObject = paramsObj(product);
                    if (!!paramsObject) {
                        dynamicCapsPricing(product, paramsObject);
                    }
                }
            });
        }

        // start dynamicPricing
        init(settings.products);
    }

    /**
     * updateUrl - public function to update the existing query string with new parameters on the product
     * @param  {object} product - an individual itinerary (ie. group of voyages)
     * @param  {string} params          - query string
     * @param  {string} filterAttr      - data attribute to hold filters when product is not a link
     */
    window.dynamicPricing.updateUrl = (product, params, filterAttr) => {
        if (product.tagName === 'A') {
            const href = product.getAttribute('href').split('?');
            product.getAttribute('href', href[0] + '?' + params);
        } else {
            product.setAttribute(filterAttr, params);
        }
    };

    /**
     * voyageCurrency - public function used to display the currency for the current dynamic pricing
     * @param  {object} voyageCurrency - an element in the disclaimer section of the page
     * @param  {string} currencyCode           - currency code provided in the dynamic pricing results
     */
    window.dynamicPricing.voyageCurrency = (voyageCurrency, currencyCode) => {
        if (!!voyageCurrency && voyageCurrency.dataset.currency !== currencyCode) {
            voyageCurrency.innerHTML = currencyCode;
            voyageCurrency.dataset.currency = currencyCode;
        }
    };

    /**
     * displayPricing - public function that assembles the markup for the line of text to display the pricing (cruise fare or per-diem)
     * @param {object} product - an individual itinerary (ie. group of voyages)
     * @param {string} targetSelector  - target a specific element in the link
     * @param {string} template        - data attribute to source code
     * @param {number} fare            - the pricing to be displayed
     * @param {number} brochureFare    - brochure fare to be displayed only if turned on
     * @param {boolean} isPerDiem      - flag to determine if 'per diem' should be appended to the pricing
     * @param {string} metaName        - name of meta for current pricing
     * @param {boolean} showMeta       - data attribute to determine meta name should be appended to the default pricing, default is to inluce
     * @param {boolean} showBrochureFare - flag to check if brochure fare is to be displayed
     */
    window.dynamicPricing.displayPricing = (product, targetSelector, template, fare, brochureFare, isPerDiem, metaName, showMeta, showBrochureFare) => {
        let fareTemplate,
            formattedFare = new Intl.NumberFormat('en-' + localeData.country, { style: 'currency', currency: localeData.primaryCurrency, minimumFractionDigits: 0 }).format(fare),
formattedBrochureFare = new Intl.NumberFormat('en-' + localeData.country, { style: 'currency', currency: localeData.primaryCurrency, minimumFractionDigits: 0 }).format(brochureFare),
            target = (targetSelector !== undefined && product.querySelectorAll(targetSelector).length > 0) ? product.querySelector(targetSelector) : product;

        if (!!product.getAttribute(template)) {
            fareTemplate = product.getAttribute(template);
        } else {
            fareTemplate = 'from {{fare}}';

            // check to include meta
            if (product.getAttribute(showMeta) === undefined) {
                fareTemplate = '{{meta}} ' + fareTemplate;
            } else {
                if (product.getAttribute(showMeta) === 'true') {
                    fareTemplate = '{{meta}} ' + fareTemplate;
                }
            }

            // add existing text
            fareTemplate = target.textContent + ' ' + fareTemplate;
        
        }

        if (!(isPerDiem) && product.getAttribute(showBrochureFare) === 'true') {
              fareTemplate += '<p class="pricing-strikethrough"> {{brochureFare}} per person </p>' ; 
        }
        
        const fareText = (isPerDiem) ? formattedFare + ' per day' : formattedFare;
        fareTemplate = fareTemplate.replace(/{{fare}}/i, fareText);
        fareTemplate = fareTemplate.replace(/{{meta}}/i, metaName);
        fareTemplate = fareTemplate.replace(/{{brochureFare}}/i, formattedBrochureFare);

        target.innerHTML = fareTemplate;
        target.classList.remove('hidden');
        target.dispatchEvent(new CustomEvent('dynamicPricing.displayPricing'));

        if (product.getAttribute('aria-label')) {
            product.getAttribute('aria-label', fareTemplate);
        }
    }
})();