window.guestSession = {
    /**
     * To better track the beginning of the Guest Session
     * Create a new session cookie when the user comes to site and store sessionID in it
     */
    trackGuestSession: () => {
        const hostDomainEnv = hostDomain[sub_domain_substring[0]].envir;
        const getSessionCookieName = hostDomainEnv === 'prod' ? 'pcl-guestSession' : `pcl-guestSession-${hostDomainEnv}`;
        // Check for the existence of a session cookie, if not present then create one and store timestamp in it.
        if (typeof docCookies === 'object' && !docCookies.hasItem(getSessionCookieName)) {
            const storeTimestamp = {
                "sessionID": Math.floor(new Date().getTime() / 1000)
            };
            docCookies.setItem(getSessionCookieName, JSON.stringify(storeTimestamp), '', '/', 'princess.com', true);
        }
        guestSession.parseSessionID(getSessionCookieName);
    },

    // Save sessionID in the data Layer
    parseSessionID: (getSessionCookieName) => {
        const guestSessionObj = JSON.parse(docCookies.getItem(getSessionCookieName));
        if (!!guestSessionObj && guestSessionObj.sessionID) {
            window.digitalData = window.digitalData || {};
            window.digitalData.page = window.digitalData.page || {};
            const guestSessionID = {
                "sessionID": guestSessionObj.sessionID
            };
            if (!window.digitalData.page.sessionID) {
                window.digitalData.page = Object.assign(window.digitalData.page, guestSessionID);
            }
        }
    }
}
window.guestSession.trackGuestSession();